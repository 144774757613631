import { AxiosResponse } from 'axios';
import { Device } from '@twilio/voice-sdk';
import {
  Utils,
  Messages,
  ActionQueue,
  ActionQueueType,
} from '@gv/triage-components';

import { api } from 'api';
import { Routes } from 'config';
import { getTokens } from 'utils/storage';
import { TextOutPayload } from 'api/comm/types';
import { pickUpStreamRoom } from 'utils/config';
import { setLoading } from 'store/slices/loader';
import { selectClient } from 'store/api/clients';
import { ActionQueueTypeNumber } from 'types/data';
import { createAppAsyncThunk } from 'store/helpers';
import { setMessages } from 'store/slices/chat-messages';
import { selectHospital } from 'store/api/hospitals/selectors';
import { createCase, updateCase } from 'store/api/cases/thunks';
import {
  setChatEnded,
  setGetPetIDs,
  setVideoCallEnded,
} from 'store/slices/call';
import {
  getQueueItemId,
  conversationName,
  fetchActionCenterCaseForm,
} from 'utils/helpers';

import {
  addTask,
  updateTask,
  setTaskOnHold,
  SMSBatchPayload,
  setClientInProgress,
} from '.';
import {
  selectCurrentTask,
  selectCurrentTaskId,
  selectCurrentTaskForm,
  selectIsSetClientInProgress,
} from './selectors';
import {
  types,
  OnHoldPayload,
  CallDVMPayload,
  CallOnHoldPayload,
  ContactsBatchResult,
  TextOutBatchPayload,
  SetCurrentTaskClient,
  ResponseCommQueueProps,
} from './types';

enum EventType {
  Picking = 'PICKING',
  NotAllowed = 'NOT_ALLOWED',
}

export const setCurrentCallOnHold = createAppAsyncThunk(
  types.setCurrentCallOnHold,
  async (props: CallOnHoldPayload, { dispatch, getState }) => {
    const state = getState();
    const task = selectCurrentTask(state);
    const userId = state?.auth.user?.id;

    if (!task || !userId) {
      return Promise.reject(new Error(Messages.NO_TASK));
    }

    if (task.type !== ActionQueueType.Voice) {
      return Promise.reject(new Error(Messages.ONLY_CALL_SUPPORTED));
    }

    if (task.isConnectedByMe) {
      try {
        await api.comm.onHoldCall({
          CSID: task.CSID,
          roomId: task.roomId,
        });
      } catch (error) {
        console.error(error);
      }
    }

    const { call, setCall } = props;

    console.log('call disconnected by hold', call?.parameters.CallSid);
    call?.disconnect();
    setCall(undefined);

    await dispatch(
      updateTask({
        changes: {
          ...task,
          isHolding: true,
          putOnHoldAt: new Date().getTime(),
        },
      })
    );
  }
);

export const responseCommQueue = createAppAsyncThunk(
  types.responseCommQueue,
  async (props: ResponseCommQueueProps, { dispatch, getState }) => {
    const state = getState();
    const { token } = getTokens();
    const {
      task,
      navigate,
      activeCall,
      ablyChannel,
      setActiveCall,
      isLocal = false,
      isContinue = false,
      twilioDevice: twilio,
    } = props;

    console.log(
      'response on comm',
      task.type,
      getQueueItemId(task),
      isContinue
    );

    try {
      if (!ablyChannel) {
        return await Promise.reject(
          new Error(Messages.INTERNET_CONNECTIVITY_ERROR)
        );
      }

      const user = state?.auth.user;
      if (!user) {
        return await Promise.reject(
          new Error(Messages.INTERNET_CONNECTIVITY_ERROR)
        );
      }

      console.log('user', user, task);

      if (
        user.statesName.length &&
        task.h_state &&
        task.is_local === 'false' &&
        user.statesName.indexOf(task.h_state) == -1
      ) {
        return await Promise.reject(
          new Error(
            task.type === ActionQueueType.Voice
              ? Messages.NOT_ALLOWED_TO_PICK
              : Messages.NOT_ALLOWED_TO_PICK_CONVO
          )
        );
      }

      // TODO: maybe need to add handleCreateUpdateCase

      const name = conversationName(user);
      const currentTask = selectCurrentTask(state);
      const isActionCenterURL = location.pathname.endsWith(
        'action-center/active'
      );

      if (
        currentTask &&
        currentTask.type === ActionQueueType.Voice &&
        !currentTask.disconnected &&
        !currentTask.isHolding
      ) {
        await dispatch(
          setCurrentCallOnHold({
            call: activeCall,
            setCall: setActiveCall,
          })
        ).unwrap();
      }

      const isSame =
        currentTask && getQueueItemId(currentTask) === getQueueItemId(task);
      switch (task.type) {
        case ActionQueueType.Voice:
          if (!twilio || twilio.state !== Device.State.Registered) {
            return await Promise.reject(
              new Error(Messages.STILL_TWILIO_DEVICE_SETUP_IS_NOT_DONE)
            );
          }
          if (state?.call?.isOutboundCall) {
            return await Promise.reject(new Error(Messages.JOIN_ANOTHER_CALL));
          }
          dispatch(setLoading(true));
          const isLocalContinue = isContinue && (task.disconnected || isLocal);
          console.log('disconnect all');
          twilio.disconnectAll();
          if (isLocalContinue) {
            const caseForm = await fetchActionCenterCaseForm(task, user);

            setActiveCall(undefined);

            dispatch(addTask({ task, form: caseForm }));

            if (!isSame) {
              navigate(Routes.ActionCenter.Comm);
            }
            dispatch(setLoading(false));
          } else {
            const callResponse = await api.comm.pickupCall({
              name,
              id: user.id,
              user_id: user.name,
              roomId: task.roomId,
            });
            const data = callResponse.data.data.data;
            const { title, status } = data;

            if (status === 1 && title === EventType.Picking) {
              if (twilio && twilio.state === Device.State.Registered) {
                const callObject = data.callObject as ActionQueue;
                const { receiver_id } = callObject;

                const caseForm = await fetchActionCenterCaseForm(
                  callObject,
                  user
                );
                if (!caseForm.priority_of_concern) {
                  caseForm.priority_of_concern = task.priority;
                }

                const call = await twilio.connect({
                  params: {
                    name,
                    To: data.roomId,
                    id: String(user?.id),
                    receiver_id: JSON.stringify(receiver_id),
                  },
                });
                console.log('connect to room', data.roomId);

                setActiveCall(call);
                dispatch(addTask({ form: caseForm, task: callObject }));

                if (!isSame) {
                  navigate(Routes.ActionCenter.Comm);
                }
                dispatch(setLoading(false));
              } else {
                console.error('wrong device status', twilio);
                await api.comm.deviceError({ user_id: name });
                dispatch(setLoading(false));
              }
            } else {
              dispatch(setLoading(false));
              // Maybe set current task null
              const isNotAllowed =
                status === 0 && title === EventType.NotAllowed;
              return await Promise.reject(
                new Error(
                  isNotAllowed
                    ? Messages.NOT_ALLOWED_TO_PICK
                    : Messages.NOT_ALLOWED_TO_PICK_ALREADY_ANSWERED
                )
              );
            }
          }
          break;

        case ActionQueueType.Chat:
          dispatch(setLoading(true));
          if (task.petIds) {
            dispatch(setGetPetIDs(task.petIds));
          } else {
            console.warn('task.petIds is undefined');
          }
          console.log('publish picked chat', ablyChannel.name);
          ablyChannel.publish('Picked-Chat', {
            name,
            id: user.id,
            type: 'Picked-Chat',
            channel_name: task.channelName,
          });
          dispatch(setChatEnded(false));
          dispatch(setLoading(false));

          if (task.channelName) {
            await api.comm.takeChat({
              name,
              channelName: task.channelName,
            });
            const caseForm = await fetchActionCenterCaseForm(task, user);
            console.log('caseForm', caseForm, task);
            dispatch(addTask({ task, form: caseForm }));
            navigate(Routes.ActionCenter.Comm);
          }
          break;

        case ActionQueueType.SMS:
          dispatch(setLoading(true));

          if (task.channelName) {
            let chat;
            if (isContinue) {
              const chatResponse = await api.comm.getChat(task.channelName);
              chat = chatResponse.data.data;
            } else {
              const chatResponse = await api.comm.takeChat({
                name,
                channelName: task.channelName,
              });
              chat = chatResponse.data.data.chat;
            }

            const caseForm = await fetchActionCenterCaseForm(chat, user);

            dispatch(addTask({ task: chat, form: caseForm }));

            const chatMessagesResponse = await api.comm.getChatMessages({
              channelName: chat.channel_name,
            });

            dispatch(setChatEnded(false));
            dispatch(
              setMessages({
                channelName: chat.channel_name,
                data: chatMessagesResponse.data.data,
              })
            );

            navigate(Routes.ActionCenter.Comm);
          }

          dispatch(setLoading(false));
          break;

        case ActionQueueType.Video:
          dispatch(setLoading(true));
          dispatch(setVideoCallEnded(false));
          const videoCallResponse = await api.comm.pickupCall({
            name: name,
            id: user.id,
            user_id: user.name,
            roomId: task.roomId,
          });
          const data = videoCallResponse.data.data.data;
          const { title, status } = data;

          if (status === 1 && title === EventType.Picking) {
            pickUpStreamRoom({ startVideo: true, userId: task.u_id });
            const videoCallObject = data.callObject as ActionQueue;

            const caseForm = await fetchActionCenterCaseForm(
              videoCallObject,
              user
            );
            if (!caseForm.priority_of_concern) {
              caseForm.priority_of_concern = task.priority;
            }
            await api.videoCall.updateVideoCallUser(
              task.channelName,
              user.hospital_id,
              {
                status: 2,
                team_user_id: user.id,
                room_id: task.channelName,
              },
              token,
              dispatch
            );
            await api.videoCall.assignVideoCall(
              task.roomId,
              user.name,
              user.id
            );
            dispatch(addTask({ form: caseForm, task: videoCallObject }));

            if (!isSame) {
              navigate(Routes.ActionCenter.Comm);
            }
            dispatch(setLoading(false));
          } else if (isActionCenterURL && name === `${user.name}_${user.id}`) {
            const caseForm = await fetchActionCenterCaseForm(task, user);
            if (!caseForm.priority_of_concern) {
              caseForm.priority_of_concern = task.priority;
            }
            dispatch(addTask({ task, form: caseForm }));

            if (!isSame) {
              navigate(Routes.ActionCenter.Comm);
            }
            dispatch(setLoading(false));
          } else {
            dispatch(setLoading(false));
            const isNotAllowed = status === 0 && title === EventType.NotAllowed;
            return await Promise.reject(
              new Error(
                isNotAllowed
                  ? Messages.NOT_ALLOWED_TO_PICK
                  : Messages.NOT_ALLOWED_TO_PICK_ALREADY_ANSWERED
              )
            );
          }
          dispatch(setLoading(false));
          break;

        default:
          return await Promise.reject(new Error(Messages.NOT_SUPPORTED_YET));
      }
    } catch (error) {
      dispatch(setLoading(false));
      return Promise.reject(error);
    }
  }
);

export const setCurrentTaskClient = createAppAsyncThunk(
  types.setCurrentTaskClient,
  async (props: SetCurrentTaskClient, { dispatch, getState }) => {
    const state = getState();
    const task = selectCurrentTask(state);
    const taskForm = selectCurrentTaskForm(state);

    if (!task) {
      return Promise.reject(new Error(Messages.NO_TASK));
    }
    if (!taskForm) {
      return Promise.reject(new Error(Messages.NO_CASE));
    }

    const conversationId = getQueueItemId(task);

    if (selectIsSetClientInProgress(task)(state)) {
      console.log(
        "setCurrentTaskClient ignored because it's a duplicate of",
        conversationId
      );
      return;
    }

    try {
      dispatch(setClientInProgress({ task, inProgress: true }));

      const { client } = props;
      const { c_id, case_id } = task;

      const clientPets = client.pet || client.pets;
      const firstPetId = clientPets?.length > 0 && clientPets[0].id;
      const pet_id = firstPetId ? firstPetId : null;
      const pets = firstPetId ? [firstPetId] : [];

      const alreadyClient = c_id && c_id > 0;

      console.log('setCurrentTaskClient', conversationId);

      if (case_id && alreadyClient) {
        const currentCase = state?.actionQueue.taskForms[conversationId];
        if (!currentCase) {
          throw new Error(Messages.NO_CASE);
        }

        const updatePayload = { ...currentCase };
        updatePayload.pets = pets;
        updatePayload.pet_id = pet_id;
        updatePayload.client_id = client.id;
        updatePayload.client_name = client.name;
        updatePayload.client_phone = client.phone;
        updatePayload.hospital_id = client.hospital_id;
        updatePayload.email = client.email ? client.email : '';

        await dispatch(
          updateCase({ task, isClientDone: true, values: updatePayload })
        ).unwrap();
      } else {
        dispatch(setLoading(true));

        const case_type =
          typeof task.type === 'string'
            ? ActionQueueTypeNumber[task.type]
            : task.type;

        const newCase = {
          ...taskForm,
          pets,
          pet_id,
          case_type,
          activity_type: '',
          email: client.email,
          client_id: client.id,
          client_name: client.name,
          client_phone: client.phone,
          hospital_id: client.hospital_id,
        };
        await dispatch(
          createCase({
            task,
            values: newCase,
            isClientDone: true,
          })
        ).unwrap();
      }
      dispatch(setLoading(false));
      dispatch(setClientInProgress({ task, inProgress: false }));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setClientInProgress({ task, inProgress: false }));
      return Promise.reject(error);
    }
  }
);

const getContactFromRequestResult = (data: string): string => {
  const parsed = JSON.parse(data);

  let contact;
  if (parsed.email) {
    contact = parsed.email;
  } else {
    contact = Utils.Helpers.formatPhone(parsed.phone || parsed.p_no);
  }

  return contact;
};

const handleContactsPromises = async (promises: Promise<AxiosResponse>[]) => {
  const results = await Promise.allSettled(promises);

  const successful: string[] = [];
  const failed: { reason: any; contact: string }[] = [];
  results.forEach((result) => {
    if (result.status === 'rejected') {
      failed.push({
        reason: Utils.Error.parseAxiosError(result.reason),
        contact: getContactFromRequestResult(result.reason.config.data),
      });
    } else {
      successful.push(getContactFromRequestResult(result.value.config.data));
    }
  });

  return {
    successful: successful.length > 0 ? successful.join('\n') : undefined,
    failed:
      failed.length > 0
        ? failed
            .map(({ reason, contact }) => `${contact}: ${reason}`)
            .join('\n')
        : undefined,
  };
};

export const textOut = createAppAsyncThunk(
  types.textOut,
  async (
    props: TextOutBatchPayload,
    { getState }
  ): Promise<ContactsBatchResult> => {
    const state = getState();
    const currentTask = selectCurrentTask(state);
    const currentTaskId = selectCurrentTaskId(state);
    const client = selectClient(String(currentTask?.c_id))(state);
    const hospital = selectHospital(String(currentTask?.h_id))(state);
    const { note, phones, emails, service, referrals, ...rest } = props;

    const hospitalId = currentTask?.h_id;

    const promises = [];

    if (hospitalId && currentTaskId && hospital) {
      const basePayload: TextOutPayload = {
        hospitalId,
        roomId: currentTaskId,
        ...(note && { note }),
        ...rest,
      };

      if (phones) {
        promises.push(
          ...phones.map((phone) =>
            api.comm.textOut({
              phone,
              ...basePayload,
            })
          )
        );
      }
      if (emails) {
        const baseEmailsPayload = {
          clientName: client?.name,
          hospitalName: hospital?.name,
        };

        if (referrals) {
          promises.push(
            ...emails.map((email) =>
              api.comm.textOut({
                ...basePayload,
                ...baseEmailsPayload,
                email,
                hospitals: referrals.map((referral) => ({
                  address: referral.address,
                  hospitalName: referral.name,
                  distance: referral.distance,
                  hospitalPhone: referral.phone,
                })),
              })
            )
          );
        } else if (service) {
          promises.push(
            ...emails.map((email) =>
              api.comm.textOut({
                ...baseEmailsPayload,
                ...basePayload,
                email,
                serviceName: service.name,
                servicePhone: service.phone,
                priceRange: service.priceRange,
              })
            )
          );
        } else {
          promises.push(
            ...emails.map((email) =>
              api.comm.textOut({
                email,
                ...basePayload,
                ...baseEmailsPayload,
              })
            )
          );
        }
      }
    }

    return handleContactsPromises(promises);
  }
);

export const sendSMS = createAppAsyncThunk(
  types.sendSMS,
  async (
    props: SMSBatchPayload,
    { getState }
  ): Promise<ContactsBatchResult> => {
    const state = getState();
    const currentTask = selectCurrentTask(state);
    const client = selectClient(String(currentTask?.c_id))(state);
    const user = state?.auth?.user;
    const { raw, phones, message, ...rest } = props;

    let hospitalId: number | undefined;

    if (!raw) {
      if (client && !currentTask?.is_dvm) {
        hospitalId = client.hospital_id;
      } else if (currentTask) {
        hospitalId = Number(currentTask.h_id);
      } else if (user) {
        hospitalId = user.hospital_id;
      }
    }

    const promises = phones.map((phone) =>
      api.comm.sendCustomSMS({
        p_no: phone,
        body: message,
        user_id: user?.id,
        ...(hospitalId && {
          hospital_id: hospitalId,
        }),
        ...rest,
      })
    );

    return handleContactsPromises(promises);
  }
);

export const setOnHold = createAppAsyncThunk(
  types.setOnHold,
  async (props: OnHoldPayload, { dispatch, getState }) => {
    const state = getState();
    const task = selectCurrentTask(state);
    const userId = state?.auth.user?.id;

    if (!task || !userId) {
      return Promise.reject(new Error(Messages.NO_TASK));
    }

    if (task.type !== ActionQueueType.Voice) {
      return Promise.reject(new Error(Messages.NOT_SUPPORTED_YET));
    }

    try {
      console.log('set on hold', task.roomId);
      await api.comm.onHold({
        user_id: userId,
        CSID: task.CSID,
        room_id: task.roomId,
        hospital_id: task.h_id,
        ...props,
      });

      dispatch(
        setTaskOnHold({
          task,
          isOnHold: props.value || false,
        })
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const callDVM = createAppAsyncThunk(
  types.callDVM,
  async (props: CallDVMPayload, { getState }): Promise<ActionQueue> => {
    const state = getState();
    const task = selectCurrentTask(state);
    const user = state?.auth.user;

    if (!user || !task) {
      return Promise.reject(new Error(Messages.NO_TASK));
    }

    try {
      console.log('call to dvm');
      const name = conversationName(user);

      const { dvmId, phone, dvmName } = props;

      const response = await api.comm.callToDvm({
        name,
        id: user.id,
        roomId: task.roomId,
        transferCallData: {
          dvm_id: dvmId ?? '',
          dvmNumberToCall: phone,
          dvmName: dvmName ?? '',
        },
      });

      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
